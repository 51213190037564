.ch-button-link {
    text-decoration: none;
    margin: 0px 10px;
}

.ch-button {
    margin: 0;
    width: 160px;
    border: 2px white solid;
    color: white;
    border-radius: 0.75em;
    padding: 10px;
    cursor: pointer;
}

.ch-button-inverted {
    margin: 0;
    width: 280px;
    border: 2px white solid;
    background-color: white;
    color: #081123;
    border-radius: 0.75em;
    padding: 10px;
    cursor: pointer;
}

.ch-button:hover {
    background-color: white;
    color: #081123;
}

.ch-button-inverted:hover {
    background-color: #081123;
    color: white;
}
