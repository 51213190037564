.sponsor-hero-text {
    width: 100%;
    margin: 1em 4em;
}

.arrow-positioning {
    position: absolute;
    display: flex;
    top: calc(100vh - 70px);
    right: 6em;
    justify-content: center;
    align-items: center;
}
