.ch-sponsor-tier-table {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    flex-direction: column;
}

.ch-platinum-normal {
    background-color: #26b24b;
    border-spacing: 0px;
}

.ch-platinum-text {
    color: #fff;
}
