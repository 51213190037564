@keyframes rotation-y {
  from {
    transform: rotateY(0deg);
  }
  to {
    transform: rotateY(360deg);
  }
}

.shadow {
  border-radius: 50%;
  width: 90px;
  height: 10px;
  background-color: #060819;
  text-align: center;
  margin: auto;
  filter: blur(8px);
  -webkit-filter: blur(8px);
}

@keyframes bounce-animation {
  0% {
    margin-top: 0px;
    margin-bottom: 0px;
  }
  50% {
    margin-top: -40px;
    margin-bottom: 40px;
  }
  100% {
    margin-top: 0px;
    margin-bottom: 0px;
  }
}

@keyframes shadow-breathe {
  0% {
    width: 90px;
    height: 10px;
    margin-bottom: 0px;
  }
  50% {
    width: 60px;
    height: 8px;
    margin-bottom: 2px;
  }
  100% {
    width: 90px;
    height: 10px;
    margin-bottom: 0px;
  }
}

@media (prefers-reduced-motion: no-preference) {
  .infinite-rotation-y {
    animation: rotation-y infinite 7s linear,
      bounce-animation infinite 7s ease-in-out;
  }

  .shadow {
    animation: shadow-breathe infinite 7s ease-in-out;
  }
}
