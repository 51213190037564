.ch-sponsor-card {
    position: relative;
    text-decoration: none;
    border: 1px solid #081123;
    -webkit-box-shadow: 5px 5px 10px 0px rgba(112, 112, 112, 1);
    -moz-box-shadow: 5px 5px 10px 0px #707070;
    box-shadow: 5px 5px 10px 0px rgba(112, 112, 112, 1);
}

.ch-card-title {
    margin: 0px;
    padding: 10px;
    background-color: #081123;
}

.ch-card-image {
    overflow: hidden;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    box-sizing: border-box;
    justify-content: end;
}

.ch-card-learn-more {
    position: absolute;
    background-color: #081123;
    padding: 5px;
    bottom: 10px;
    right: 10px;
    margin: 0;
}
