.right-arrow-positioning {
    position: absolute;
    display: flex;
    top: calc(100vh - 70px);
    right: 6em;
    justify-content: center;
    align-items: center;
}

.ch-home-hero {
    display: flex;
    flex-direction: column;
}

.ch-home-hero-text {
    margin: 0px;
}

.ch-button-container-center {
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.ch-button-container-left {
    display: flex;
    flex-direction: row;
}
